import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../Loading/LoadingDialog";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { ADD_TEAM } from "../../GraphQL/Teams";

const NewTeamDialog = (props) => {
  const [team, setTeam] = useState("");

  const [addTeam, { loading, error }] = useMutation(ADD_TEAM, {
    variables: {
      managerId: props.managerId,
      name: team.name,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            addTeam();
            props.setOpen(false);
          } else if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Team</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setTeam({ ...team, name: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveNewTeamDialogButton"
            onClick={() => {
              addTeam();
              props.setOpen(false);
            }}
          >
            Create
          </Button>
          <Button
            data-testid="closeNewTeamDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewTeamDialog;
