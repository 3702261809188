import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const MatchButton = (props) => {
  return (
    <div>
      <Button
        component={Link}
        to={"/match/" + props.matchId}
        sx={{ marginLeft: 1 }}
        variant="contained"
      >
        Match
      </Button>
    </div>
  );
};

export default MatchButton;
