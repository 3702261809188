import * as React from "react";
import "../../Styles/style.css";
import ResetButton from "../../Components/Admin/ResetButton.js";

const AdminPage = () => {
  return (
    <div className="container">
      <ResetButton />
    </div>
  );
};

export default AdminPage;
