import { useState } from "react";
import { Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NewTeamDialog from "./NewTeamDialog";

const NewTeamButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box display="flex">
        <Button
          data-testid="newTeamButton"
          sx={{ marginLeft: 1 }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          Create Team
        </Button>
      </Box>

      <NewTeamDialog
        open={open}
        setOpen={setOpen}
        managerId={props.managerId}
      ></NewTeamDialog>
    </div>
  );
};

export default NewTeamButton;
