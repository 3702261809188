import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import UserTable from "../../Components/User/UserTable.js";
import { GET_USERS } from "../../GraphQL/Users";
import { LoginContext } from "../../Components/Login/LoginContext";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import "../../Styles/style.css";

const UsersPage = () => {
  const users = useQuery(GET_USERS, {
    pollInterval: 5000,
  });

  const [loggedIn] = useContext(LoginContext);

  if (users.loading) return <LoadingDialog />;

  return (
    <div className="container">
      <h3>Users</h3>
      <UserTable users={users.data.users} showActions={loggedIn}></UserTable>
    </div>
  );
};

export default UsersPage;
