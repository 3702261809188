import { useContext } from "react";
import { LoginContext } from "../Components/Login/LoginContext";
import {
  AppBar,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import NavigationDrawer from "./NavigationDrawer";
import NavigationBar from "./NavigationBar";

const Navigation = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [loggedIn, setLoggedIn] = useContext(LoginContext);

  return (
    <nav>
      <AppBar>
        <Toolbar>
          <Typography sx={{ color: "white" }}>
            <SportsSoccerIcon></SportsSoccerIcon>
          </Typography>
          {isMatch ? (
            <NavigationDrawer showActions={loggedIn}></NavigationDrawer>
          ) : (
            <NavigationBar showActions={loggedIn}></NavigationBar>
          )}
        </Toolbar>
      </AppBar>
    </nav>
  );
};

export default Navigation;
