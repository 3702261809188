import { gql } from "@apollo/client";

export const GET_COMPETITIONS = gql`
  query GetCompetitions {
    competitions {
      id
      name
      description
      url
      user {
        id
        username
      }
    }
  }
`;

export const ADD_COMPETITION = gql`
  mutation createCompetition(
    $name: String!
    $description: String!
    $url: String!
  ) {
    createCompetition(name: $name, description: $description, url: $url) {
      id
      name
      description
      url
      user {
        id
        username
      }
    }
  }
`;

export const GET_COMPETITION = gql`
  query competition($id: Long) {
    competition(id: $id) {
      id
      name
      description
      url
      user {
        id
        username
      }
    }
  }
`;

export const UPDATE_COMPETITION = gql`
  mutation updateCompetition(
    $id: Long!
    $name: String!
    $description: String!
    $url: String!
  ) {
    updateCompetition(
      id: $id
      name: $name
      description: $description
      url: $url
    ) {
      id
      name
      description
      url
      user {
        id
        username
      }
    }
  }
`;

export const DELETE_COMPETITION = gql`
  mutation deleteCompetition($id: Long!) {
    deleteCompetition(id: $id) {
      id
      name
      description
      url
      user {
        id
        username
      }
    }
  }
`;

export const GET_COMPETITIONS_BY_USER_ID = gql`
  query competitionsByUserId($id: Long) {
    competitionsByUserId(id: $id) {
      id
      name
      description
      url
      user {
        id
        username
      }
    }
  }
`;

export const COMPETITIONS_WITH_PERMISSION = gql`
  query competitionsWithPermission {
    competitionsWithPermission {
      id
    }
  }
`;
