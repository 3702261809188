import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { DELETE_SEASON } from "../../GraphQL/Season";

const DeleteSeasonDialog = (props) => {
  const [season, setSeason] = useState("");

  useEffect(() => {
    setSeason(props.season);
  }, [props]);

  const [deleteSeason, { loading, error }] = useMutation(DELETE_SEASON, {
    variables: {
      id: props.season.id,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Season</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.season.name}
            disabled
          />
          <TextField
            data-testid="startDate"
            margin="dense"
            id="startDate"
            label="Start Date"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.season.startDate}
            disabled
          />
          <TextField
            data-testid="endDate"
            margin="dense"
            id="endDate"
            label="End Date"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.season.endDate}
            disabled
          />
          <TextField
            data-testid="competition"
            margin="dense"
            id="competition"
            label="Competition"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.season.competition}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveDeleteSeasonDialogButton"
            onClick={() => {
              deleteSeason();
              props.setOpen(false);
            }}
          >
            Delete
          </Button>
          <Button
            data-testid="closeDeleteSeasonDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteSeasonDialog;
