import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_APPEARANCES_BY_PLAYER_ID } from "../../GraphQL/Appearances.js";
import { GET_PLAYER } from "../../GraphQL/Players.js";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import AppearanceTable from "../../Components/Appearance/AppearanceTable.js";
import TeamButton from "../../Components/Team/TeamButton.js";
import PlayerOverview from "../../Components/Player/PlayerOverview";
import UserButton from "../../Components/User/UserButton.js";
import "../../Styles/style.css";

const PlayerPage = () => {
  const { playerId } = useParams();

  const player = useQuery(GET_PLAYER, {
    variables: {
      id: playerId,
    },
  });

  const appearances = useQuery(GET_APPEARANCES_BY_PLAYER_ID, {
    variables: {
      id: playerId,
    },
    pollInterval: 5000,
  });

  if (appearances.loading || player.loading) return <LoadingDialog />;

  return (
    <div className="container">
      <h3>Player</h3>
      <PlayerOverview player={player.data.player}></PlayerOverview>
      {player.data.player.team && (
        <TeamButton team={player.data.player.team}></TeamButton>
      )}
      <UserButton userId={player.data.player.user.id}></UserButton>
      <h3>Appearances</h3>
      <AppearanceTable
        appearancesWithPermission={[]}
        appearances={appearances.data.appearancesByPlayerId}
      ></AppearanceTable>
    </div>
  );
};

export default PlayerPage;
