import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const UserButton = (props) => {
  return (
    <div>
      <Button
        component={Link}
        to={"/user/" + props.userId}
        sx={{ marginLeft: 1 }}
        variant="contained"
      >
        User
      </Button>
    </div>
  );
};

export default UserButton;
