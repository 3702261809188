import React, { useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_COMPETITION } from "../../GraphQL/Competitions.js";
import CompetitionOverview from "../../Components/Competition/CompetitionOverview.js";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import SeasonsTable from "../../Components/Season/SeasonsTable.js";
import {
  GET_SEASONS_BY_COMPETITION_ID,
  SEASONS_WITH_PERMISSION,
} from "../../GraphQL/Season.js";
import { COMPETITIONS_WITH_PERMISSION } from "../../GraphQL/Competitions.js";
import { LoginContext } from "../../Components/Login/LoginContext";
import NewSeasonButton from "../../Components/Season/NewSeasonButton.js";
import "../../Styles/style.css";

const CompetitionPage = () => {
  const { competitionId } = useParams();

  const competition = useQuery(GET_COMPETITION, {
    variables: {
      id: competitionId,
    },
    pollInterval: 5000,
  });

  const seasons = useQuery(GET_SEASONS_BY_COMPETITION_ID, {
    variables: {
      id: competitionId,
    },
    pollInterval: 5000,
  });

  let competitionsWithPermission = useQuery(COMPETITIONS_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  const seasonsWithPermission = useQuery(SEASONS_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  const [loggedIn] = useContext(LoginContext);

  if (
    competition.loading ||
    seasons.loading ||
    seasonsWithPermission.loading ||
    competitionsWithPermission.loading
  )
    return <LoadingDialog />;

  competitionsWithPermission =
    competitionsWithPermission.data.competitionsWithPermission.map(
      (competition) => competition.id
    );

  return (
    <div className="container">
      <h3>Competition</h3>
      <CompetitionOverview
        competition={competition.data.competition}
      ></CompetitionOverview>
      {loggedIn &&
        competitionsWithPermission.includes(parseInt(competitionId)) && (
          <NewSeasonButton competitionId={competitionId}></NewSeasonButton>
        )}
      <h3>Seasons</h3>
      <SeasonsTable
        seasonsWithPermission={seasonsWithPermission.data.seasonsWithPermission}
        seasons={seasons.data.seasonsByCompetitionId}
      ></SeasonsTable>
    </div>
  );
};

export default CompetitionPage;
