import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_COMPETITIONS,
  COMPETITIONS_WITH_PERMISSION,
} from "../../GraphQL/Competitions.js";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import CompetitionsTable from "../../Components/Competition/CompetitionsTable.js";
import NewCompetitionButton from "../../Components/Competition/NewCompetitionButton.js";
import { LoginContext } from "../../Components/Login/LoginContext";
import "../../Styles/style.css";

const CompetitionsPage = () => {
  const competitions = useQuery(GET_COMPETITIONS, {
    pollInterval: 5000,
  });

  const competitionsWithPermission = useQuery(COMPETITIONS_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  const [loggedIn] = useContext(LoginContext);

  if (competitions.loading || competitionsWithPermission.loading)
    return <LoadingDialog />;

  return (
    <div className="container">
      <h3>Competitions</h3>
      {loggedIn && <NewCompetitionButton></NewCompetitionButton>}
      <CompetitionsTable
        competitionsWithPermission={
          competitionsWithPermission.data.competitionsWithPermission
        }
        competitions={competitions.data.competitions}
      ></CompetitionsTable>
    </div>
  );
};

export default CompetitionsPage;
