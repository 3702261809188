import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

const SeasonOverview = (props) => {
  return (
    <div>
      <Paper
        data-testid="seasonOverview"
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 500,
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {props.season.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {props.season.startDate} - {props.season.endDate}
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div"></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default SeasonOverview;
