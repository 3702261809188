import { useState, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { REGISTER } from "../../GraphQL/Login";
import { LoginContext } from "./LoginContext";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";

const RegisterDialog = (props) => {
  const [credentials, setCredentials] = useState("");
  const [loggedIn, setLoggedIn] = useContext(LoginContext);

  const [register, { loading, error }] = useMutation(REGISTER, {
    variables: {
      email: credentials.email,
      password: credentials.password,
      discordId: credentials.discordId,
      discriminator: credentials.discriminator,
      username: credentials.username,
      icon: credentials.icon,
      originId: credentials.originId,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            register();
            props.setOpen(false);
          } else if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Register</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="email"
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setCredentials({ ...credentials, email: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 50 } }}
          />
          <TextField
            data-testid="password"
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setCredentials({ ...credentials, password: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 20 } }}
          />
          <TextField
            data-testid="discordId"
            margin="dense"
            id="discordId"
            label="Discord ID"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setCredentials({ ...credentials, discordId: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 20 } }}
          />
          <TextField
            data-testid="discriminator"
            margin="dense"
            id="discriminator"
            label="Discriminator"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 9999) value = 9999;
              if (value < 0) value = 0;
              e.target.value = value;
              setCredentials({ ...credentials, discriminator: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 9999 } }}
          />
          <TextField
            data-testid="username"
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setCredentials({ ...credentials, username: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 20 } }}
          />
          <TextField
            data-testid="icon"
            margin="dense"
            id="icon"
            label="Icon"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setCredentials({ ...credentials, icon: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 20 } }}
          />
          <TextField
            data-testid="originId"
            margin="dense"
            id="originId"
            label="Origin ID"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setCredentials({ ...credentials, originId: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 20 } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveRegisterDialogButton"
            onClick={() => {
              register();
              props.setOpen(false);
            }}
          >
            Register
          </Button>
          <Button
            data-testid="closeRegisterDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RegisterDialog;
