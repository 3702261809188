import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_TRANSFER_REQUESTS_BY_PLAYER_ACCEPTED_AND_TEAM_ACCEPTED,
  TRANSFER_REQUESTS_WITH_PERMISSION,
} from "../../GraphQL/TransferRequests";
import { GET_PLAYERS } from "../../GraphQL/Players";
import { GET_TEAMS } from "../../GraphQL/Teams";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import TransferRequestsTable from "../../Components/TransferRequests/TransferRequestsTable.js";
import NewTransferRequestButton from "../../Components/TransferRequests/NewTransferRequestButton.js";
import { LoginContext } from "../../Components/Login/LoginContext";
import "../../Styles/style.css";

const TransferRequestPage = () => {
  const transferRequests = useQuery(
    GET_TRANSFER_REQUESTS_BY_PLAYER_ACCEPTED_AND_TEAM_ACCEPTED,
    {
      variables: {
        playerAccepted: false,
        teamAccepted: true,
      },
      pollInterval: 5000,
    }
  );

  const players = useQuery(GET_PLAYERS, { pollInterval: 5000 });
  const teams = useQuery(GET_TEAMS, { pollInterval: 5000 });

  const transferRequestsWithPermission = useQuery(
    TRANSFER_REQUESTS_WITH_PERMISSION,
    {
      pollInterval: 5000,
    }
  );

  const [loggedIn] = useContext(LoginContext);

  if (
    transferRequests.loading ||
    players.loading ||
    transferRequestsWithPermission.loading ||
    teams.loading
  )
    return <LoadingDialog />;

  return (
    <div className="container">
      <h3>Transfer Requests</h3>
      {loggedIn && (
        <NewTransferRequestButton
          players={players.data.players}
          teams={teams.data.teams}
        ></NewTransferRequestButton>
      )}
      <TransferRequestsTable
        transferRequestsWithPermission={
          transferRequestsWithPermission.data.transferRequestsWithPermission
        }
        transferRequests={
          transferRequests.data.transferRequestsByPlayerAcceptedAndTeamAccepted
        }
        players={players.data.players}
        teams={teams.data.teams}
      ></TransferRequestsTable>
    </div>
  );
};

export default TransferRequestPage;
