import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const PlayerButton = (props) => {
  return (
    <div>
      <Button
        data-testid="playerButton"
        component={Link}
        to={"/player/" + props.playerId}
        sx={{ marginLeft: 1 }}
        variant="contained"
      >
        Player
      </Button>
    </div>
  );
};

export default PlayerButton;
