import { useState } from "react";
import { Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NewMatchDialog from "./NewMatchDialog";

const NewMatchButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box display="flex">
        <Button
          data-testid="newMatchButton"
          sx={{ marginLeft: 1 }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          Create Match
        </Button>
      </Box>

      <NewMatchDialog
        open={open}
        setOpen={setOpen}
        seasonId={props.seasonId}
        teams={props.teams}
      ></NewMatchDialog>
    </div>
  );
};

export default NewMatchButton;
