import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditAppearanceButton from "./EditAppearanceButton";
import DeleteAppearanceButton from "./DeleteAppearanceButton";

const AppearanceTable = (props) => {
  const navigate = useNavigate();
  const handleRowClick = (row) => {
    navigate(`/appearance/${row.id}`);
  };

  const rows = props.appearances.map(
    ({
      id,
      player,
      team,
      goals,
      assists,
      passes,
      interceptions,
      tackles,
      yellowCards,
      redCards,
    }) => ({
      id: id,
      player: player.name,
      team: team.name,
      goals: goals,
      assists: assists,
      passes: passes,
      interceptions: interceptions,
      tackles: tackles,
      yellowCards: yellowCards,
      redCards: redCards,
    })
  );

  const appearancesWithPermission = props.appearancesWithPermission.map(
    (appearance) => appearance.id
  );

  return (
    <div>
      <TableContainer data-testid="appearanceTable" component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {appearancesWithPermission.length > 0 && <TableCell></TableCell>}
              <TableCell>Name</TableCell>
              <TableCell>Goals</TableCell>
              <TableCell>Assists</TableCell>
              <TableCell>Passes</TableCell>
              <TableCell>Interceptions</TableCell>
              <TableCell>Tackles</TableCell>
              <TableCell>Yellow Cards</TableCell>
              <TableCell>Red Cards</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                data-testid={"appearanceTableRow" + row.id}
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleRowClick(row)}
              >
                {appearancesWithPermission.includes(row.id) && (
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: 160 }}
                  >
                    <Box display="flex">
                      <EditAppearanceButton
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        appearance={row}
                      />
                      <DeleteAppearanceButton
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                        appearance={row}
                      />
                    </Box>
                  </TableCell>
                )}
                {!appearancesWithPermission.includes(row.id) &&
                  appearancesWithPermission.length > 0 && (
                    <TableCell></TableCell>
                  )}
                <TableCell>{row.player}</TableCell>
                <TableCell>{row.goals}</TableCell>
                <TableCell>{row.assists}</TableCell>
                <TableCell>{row.passes}</TableCell>
                <TableCell>{row.interceptions}</TableCell>
                <TableCell>{row.tackles}</TableCell>
                <TableCell>{row.yellowCards}</TableCell>
                <TableCell>{row.redCards}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AppearanceTable;
