import News from "../../Components/News";
import "../../Styles/style.css";

const HomePage = () => {
  return (
    <div className="container">
      <h1>Pro Clubs Football Association</h1>
      <News></News>
    </div>
  );
};

export default HomePage;
