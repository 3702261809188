import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import TeamTable from "../../Components/Team/TeamTable.js";
import { GET_TEAMS, TEAMS_WITH_PERMISSION } from "../../GraphQL/Teams";
import { GET_MANAGERS } from "../../GraphQL/Managers";
import { LoginContext } from "../../Components/Login/LoginContext";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import "../../Styles/style.css";

const TeamsPage = () => {
  const teams = useQuery(GET_TEAMS, {
    pollInterval: 5000,
  });
  const managers = useQuery(GET_MANAGERS, {
    pollInterval: 5000,
  });

  const [loggedIn] = useContext(LoginContext);

  const teamsWithPermission = useQuery(TEAMS_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  if (teams.loading || managers.loading || teamsWithPermission.loading)
    return <LoadingDialog />;

  return (
    <div className="container">
      <h3>Teams</h3>
      <TeamTable
        managers={managers.data.managers}
        teams={teams.data.teams}
        teamsWithPermission={teamsWithPermission.data.teamsWithPermission}
      ></TeamTable>
    </div>
  );
};

export default TeamsPage;
