import { useState } from "react";
import { Button, Box } from "@mui/material";
import NewTransferRequestDialog from "./NewTransferRequestDialog";
import AddIcon from "@mui/icons-material/Add";

const NewTransferRequestButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box display="flex">
        <Button
          data-testid="newTransferRequestButton"
          sx={{ marginLeft: 1 }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          Create Transfer Request
        </Button>
      </Box>

      <NewTransferRequestDialog
        open={open}
        setOpen={setOpen}
        players={props.players}
        teams={props.teams}
      ></NewTransferRequestDialog>
    </div>
  );
};

export default NewTransferRequestButton;
