import { useState } from "react";
import { Button, Box } from "@mui/material";
import RegisterDialog from "./RegisterDialog";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const RegisterButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box display="flex">
        <Button
          data-testid="registerButton"
          sx={{ marginLeft: "auto" }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <HowToRegIcon></HowToRegIcon>
        </Button>
      </Box>

      <RegisterDialog open={open} setOpen={setOpen}></RegisterDialog>
    </div>
  );
};

export default RegisterButton;
