import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../Loading/LoadingDialog";
import ErrorDialog from "../Error/ErrorDialog.js";
import { UPDATE_TEAM } from "../../GraphQL/Teams";
import ManagerSelect from "../../Components/Manager/ManagerSelect";

const EditTeamDialog = (props) => {
  const [team, setTeam] = useState("");
  const [manager, setManager] = useState(null);

  useEffect(() => {
    setTeam(props.team);
  }, [props]);

  const [updateTeam, { loading, error }] = useMutation(UPDATE_TEAM, {
    variables: {
      id: props.team.id,
      managerId: manager,
      name: team.name,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateTeam();
            props.setOpen(false);
          } else if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Team</DialogTitle>
        <DialogContent>
          <ManagerSelect
            managers={props.managers}
            manager={manager}
            setManager={setManager}
          />
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.team.name}
            onChange={(e) => setTeam({ ...team, name: e.target.value })}
            inputProps={{ maxLength: 20 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveEditTeamDialogButton"
            onClick={() => {
              updateTeam();
              props.setOpen(false);
            }}
          >
            Update
          </Button>
          <Button
            data-testid="closeEditTeamDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditTeamDialog;
