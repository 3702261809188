import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const TeamButton = (props) => {
  return (
    <div>
      <Button
        component={Link}
        to={"/team/" + props.team.id}
        sx={{ marginLeft: 1 }}
        variant="contained"
      >
        {props.team.name}
      </Button>
    </div>
  );
};

export default TeamButton;
