import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      username
      token
    }
  }
`;

export const REGISTER = gql`
  mutation (
    $discordId: String!
    $discriminator: Long!
    $username: String!
    $email: String!
    $icon: String!
    $originId: String!
    $password: String!
  ) {
    createUser(
      discordId: $discordId
      discriminator: $discriminator
      username: $username
      email: $email
      icon: $icon
      originId: $originId
      password: $password
    ) {
      username
    }
  }
`;
