import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteUserDialog from "./DeleteUserDialog";

const DeleteUserButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="deleteUserButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DeleteIcon />
      </Button>

      <DeleteUserDialog
        open={open}
        setOpen={setOpen}
        user={props.user}
      ></DeleteUserDialog>
    </div>
  );
};

export default DeleteUserButton;
