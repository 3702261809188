import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteMatchDialog from "./DeleteMatchDialog";

const DeleteMatchButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="deleteMatchButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DeleteIcon />
      </Button>

      <DeleteMatchDialog
        open={open}
        setOpen={setOpen}
        match={props.match}
      ></DeleteMatchDialog>
    </div>
  );
};

export default DeleteMatchButton;
