import { useState, useContext } from "react";
import { Button, Box } from "@mui/material";
import LoginDialog from "./LoginDialog";
import { LoginContext } from "./LoginContext";
import LogoutIcon from "@mui/icons-material/Logout";

const LogoutButton = (props) => {
  const [open, setOpen] = useState(false);

  const [loggedIn, setLoggedIn] = useContext(LoginContext);

  function logout() {
    localStorage.removeItem("token");
    setLoggedIn(false);
  }

  return (
    <div>
      <Box display="flex">
        <Button
          data-testid="logoutButton"
          sx={{ marginLeft: "auto" }}
          variant="contained"
          onClick={() => logout()}
        >
          <LogoutIcon></LogoutIcon>
        </Button>
      </Box>

      <LoginDialog open={open} setOpen={setOpen}></LoginDialog>
    </div>
  );
};

export default LogoutButton;
