import React from "react";
import { Select, MenuItem, InputLabel } from "@mui/material";

const ManagerSelect = (props) => {
  const handleManagerChange = (event) => {
    props.setManager(event.target.value);
  };

  const rows = props.managers.map(({ id, user }) => ({
    id: id,
    name: user.username,
  }));

  return (
    <div>
      <InputLabel id="manager-select-label">Manager</InputLabel>
      <Select
        data-testid="manager-select"
        labelId="manager-select-label"
        onChange={handleManagerChange}
        value={props.manager}
        variant="standard"
        fullWidth
        sx={{ minWidth: 150 }}
      >
        {rows.map((manager) => (
          <MenuItem key={manager.id} value={manager.id}>
            {manager.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ManagerSelect;
