import { useState } from "react";
import { Button, Box } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import RemoveTeamFromSeasonDialog from "./RemoveTeamFromSeasonDialog";

const RemoveTeamFromSeasonButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="removeTeamFromSeasonButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        <RemoveIcon />
        Remove Team
      </Button>

      <RemoveTeamFromSeasonDialog
        open={open}
        setOpen={setOpen}
        seasonId={props.seasonId}
        teams={props.teams}
      ></RemoveTeamFromSeasonDialog>
    </div>
  );
};

export default RemoveTeamFromSeasonButton;
