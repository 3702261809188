import { useState, useContext } from "react";
import { Button, Box } from "@mui/material";
import LoginDialog from "./LoginDialog";
import { LoginContext } from "./LoginContext";
import LoginIcon from "@mui/icons-material/Login";

const LoginButton = (props) => {
  const [open, setOpen] = useState(false);

  const [loggedIn, setLoggedIn] = useContext(LoginContext);

  return (
    <div>
      <Box display="flex">
        <Button
          data-testid="loginButton"
          sx={{ marginLeft: "auto" }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <LoginIcon></LoginIcon>
        </Button>
      </Box>

      <LoginDialog open={open} setOpen={setOpen}></LoginDialog>
    </div>
  );
};

export default LoginButton;
