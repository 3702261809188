import { Button } from "@mui/material";
import { RESET } from "../../GraphQL/Admin";
import { useMutation } from "@apollo/client";

const ResetButtom = (props) => {
  const [reset, { loading, error }] = useMutation(RESET, {});
  return (
    <div>
      <Button
        data-testid="resetButton"
        variant="contained"
        onClick={() => {
          reset();
        }}
      >
        Reset
      </Button>
    </div>
  );
};

export default ResetButtom;
