import React, { useState } from "react";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditCompetitionDialog from "./EditCompetitionDialog";

const EditCompetitionButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="editCompetitionButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        <EditIcon />
      </Button>

      <EditCompetitionDialog
        open={open}
        setOpen={setOpen}
        competition={props.competition}
      ></EditCompetitionDialog>
    </div>
  );
};

export default EditCompetitionButton;
