import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { UPDATE_MATCH } from "../../GraphQL/Matches";

const EditMatchDialog = (props) => {
  const [match, setMatch] = useState("");

  useEffect(() => {
    setMatch(props.match);
  }, [props]);

  const [updateMatch, { loading, error }] = useMutation(UPDATE_MATCH, {
    variables: {
      id: props.match.id,
      startDate: match.startDate,
      hasBeenPlayed: match.hasBeenPlayed,
      homeScore: parseInt(match.homeScore),
      awayScore: parseInt(match.awayScore),
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateMatch();
            props.setOpen(false);
          } else if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Match</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="homeScore"
            margin="dense"
            id="homeScore"
            label="Home Score"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.match.homeScore}
            onChange={(e) => {
              var value = parseInt(e.target.value);
              if (value < 0) value = 0;
              e.target.value = value;
              setMatch({ ...match, homeScore: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 1 } }}
          />
          <TextField
            data-testid="awayScore"
            margin="dense"
            id="awayScore"
            label="Away Score"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.match.awayScore}
            onChange={(e) => {
              var value = parseInt(e.target.value);
              if (value < 0) value = 0;
              e.target.value = value;
              setMatch({ ...match, awayScore: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 1 } }}
          />
          <TextField
            data-testid="startDate"
            autoFocus
            margin="dense"
            id="startDate"
            label="Start Date"
            type="date"
            fullWidth
            variant="standard"
            defaultValue={props.match.startDate}
            onChange={(e) => {
              setMatch({ ...match, startDate: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            label="Has Been Played?"
            control={
              <Checkbox
                data-testid="hasBeenPlayed"
                margin="dense"
                id="hasBeenPlayed"
                label="Has Been Played?"
                variant="standard"
                defaultChecked={props.match.hasBeenPlayed}
                onChange={(e) => {
                  setMatch({ ...match, hasBeenPlayed: e.target.checked });
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveEditMatchDialogButton"
            onClick={() => {
              updateMatch();
              props.setOpen(false);
            }}
          >
            Update
          </Button>
          <Button
            data-testid="closeEditMatchDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditMatchDialog;
