import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { UPDATE_PLAYER } from "../../GraphQL/Players";

const EditPlayerDialog = (props) => {
  const [player, setPlayer] = useState("");

  useEffect(() => {
    setPlayer(props.player);
  }, [props]);

  const [updatePlayer, { loading, error }] = useMutation(UPDATE_PLAYER, {
    variables: {
      id: player.id,
      name: player.name,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updatePlayer();
            props.setOpen(false);
          } else if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Player</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.player.name}
            onChange={(e) => {
              setPlayer({ ...player, name: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveEditPlayerDialogButton"
            onClick={() => {
              updatePlayer();
              props.setOpen(false);
            }}
          >
            Update
          </Button>
          <Button
            data-testid="closeEditPlayerDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditPlayerDialog;
