import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../Loading/LoadingDialog";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { DELETE_USER } from "../../GraphQL/Users";

const DeleteUserDialog = (props) => {
  const [user, setUser] = useState("");

  useEffect(() => {
    setUser(props.User);
  }, [props]);

  const [deleteUser, { loading, error }] = useMutation(DELETE_USER, {
    variables: {
      id: props.user.id,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>User</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="discriminator"
            autoFocus
            margin="dense"
            id="discriminator"
            label="Discriminator"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.discriminator}
            disabled
          />
          <TextField
            data-testid="discordId"
            margin="dense"
            id="discordId"
            label="Discord ID"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.discordId}
            disabled
          />
          <TextField
            data-testid="username"
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.username}
            disabled
          />
          <TextField
            data-testid="email"
            margin="dense"
            id="email"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.email}
            disabled
          />
          <TextField
            data-testid="icon"
            margin="dense"
            id="icon"
            label="Icon"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.icon}
            disabled
          />
          <TextField
            data-testid="originId"
            margin="dense"
            id="originId"
            label="Origin ID"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.originId}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="deleteDeleteUserDialogButton"
            onClick={() => {
              deleteUser();
              props.setOpen(false);
            }}
          >
            Delete
          </Button>
          <Button
            data-testid="closeDeleteUserDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteUserDialog;
