import { gql } from "@apollo/client";

export const GET_TRANSFER_REQUESTS_BY_PLAYER_ACCEPTED_AND_TEAM_ACCEPTED = gql`
  query transferRequestsByPlayerAcceptedAndTeamAccepted(
    $playerAccepted: Boolean
    $teamAccepted: Boolean
  ) {
    transferRequestsByPlayerAcceptedAndTeamAccepted(
      playerAccepted: $playerAccepted
      teamAccepted: $teamAccepted
    ) {
      id
      player {
        id
        name
      }
      team {
        id
        name
      }
      playerAccepted
      teamAccepted
      requestDate
    }
  }
`;

export const GET_TRANSFER_REQUESTS_BY_PLAYER_ACCEPTED_OR_TEAM_ACCEPTED = gql`
  query transferRequestsByPlayerAcceptedOrTeamAccepted {
    transferRequestsByPlayerAcceptedOrTeamAccepted {
      id
      player {
        id
        name
      }
      team {
        id
        name
      }
      playerAccepted
      teamAccepted
      requestDate
    }
  }
`;

export const UPDATE_TRANSFER_REQUEST = gql`
  mutation updateTransferRequest(
    $id: Long!
    $playerId: Long!
    $teamId: Long!
    $playerAccepted: Boolean!
    $teamAccepted: Boolean!
    $requestDate: Date!
  ) {
    updateTransferRequest(
      id: $id
      playerId: $playerId
      teamId: $teamId
      playerAccepted: $playerAccepted
      teamAccepted: $teamAccepted
      requestDate: $requestDate
    ) {
      id
      player {
        id
        name
      }
      team {
        id
        name
      }
      playerAccepted
      teamAccepted
      requestDate
    }
  }
`;

export const DELETE_TRANSFER_REQUEST = gql`
  mutation deleteTransferRequest($id: Long!) {
    deleteTransferRequest(id: $id) {
      id
      player {
        id
        name
      }
      team {
        id
        name
      }
      playerAccepted
      teamAccepted
      requestDate
    }
  }
`;

export const ADD_TRANSFER_REQUEST = gql`
  mutation createTransferRequest(
    $playerId: Long!
    $teamId: Long!
    $playerAccepted: Boolean!
    $teamAccepted: Boolean!
    $requestDate: Date!
  ) {
    createTransferRequest(
      playerId: $playerId
      teamId: $teamId
      playerAccepted: $playerAccepted
      teamAccepted: $teamAccepted
      requestDate: $requestDate
    ) {
      id
      player {
        id
        name
      }
      team {
        id
        name
      }
      playerAccepted
      teamAccepted
      requestDate
    }
  }
`;

export const TRANSFER_REQUESTS_WITH_PERMISSION = gql`
  query transferRequestsWithPermission {
    transferRequestsWithPermission {
      id
    }
  }
`;
