import { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Link } from "react-router-dom";
import LoginButton from "../Components/Login/LoginButton.js";
import LogoutButton from "../Components/Login/LogoutButton.js";
import RegisterButton from "../Components/Login/RegisterButton.js";
import ProfileButton from "../Components/Profile/ProfileButton.js";

const NavigationDrawer = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div data-testid="navigationDrawer">
      <Drawer open={open}>
        <List>
          {!props.showActions && (
            <ListItemButton key={1} divider>
              <ListItemIcon>
                <LoginButton></LoginButton>
              </ListItemIcon>
            </ListItemButton>
          )}
          {!props.showActions && (
            <ListItemButton key={2} divider>
              <ListItemIcon>
                <RegisterButton></RegisterButton>
              </ListItemIcon>
            </ListItemButton>
          )}
          {props.showActions && (
            <ListItemButton key={3} divider>
              <ListItemIcon>
                <ProfileButton></ProfileButton>
              </ListItemIcon>
            </ListItemButton>
          )}
          {props.showActions && (
            <ListItemButton key={4} divider>
              <ListItemIcon>
                <LogoutButton></LogoutButton>
              </ListItemIcon>
            </ListItemButton>
          )}
          <ListItemButton
            key={5}
            divider
            onClick={() => setOpen(false)}
            component={Link}
            to="/"
          >
            <ListItemIcon>
              <ListItemText sx={{ color: "white" }}>Community</ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            key={6}
            divider
            onClick={() => setOpen(false)}
            component={Link}
            to="/competitions"
          >
            <ListItemIcon>
              <ListItemText sx={{ color: "white" }}>Competitions</ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            key={7}
            divider
            onClick={() => setOpen(false)}
            component={Link}
            to="/teams"
          >
            <ListItemIcon>
              <ListItemText sx={{ color: "white" }}>Teams</ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            key={8}
            divider
            onClick={() => setOpen(false)}
            component={Link}
            to="/transferRequests"
          >
            <ListItemIcon>
              <ListItemText sx={{ color: "white" }}>
                Transfer Market
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
          {props.showActions && (
            <ListItemButton
              key={9}
              divider
              onClick={() => setOpen(false)}
              component={Link}
              to="/users"
            >
              <ListItemIcon>
                <ListItemText sx={{ color: "white" }}>Users</ListItemText>
              </ListItemIcon>
            </ListItemButton>
          )}
          {props.showActions && (
            <ListItemButton
              key={10}
              divider
              onClick={() => setOpen(false)}
              component={Link}
              to="/admin"
            >
              <ListItemIcon>
                <ListItemText sx={{ color: "white" }}>Admin</ListItemText>
              </ListItemIcon>
            </ListItemButton>
          )}
        </List>
      </Drawer>

      <IconButton
        data-testid="navigationDrawerIcon"
        sx={{ marginLeft: "auto", color: "white" }}
        onClick={() => setOpen(!open)}
      >
        <MenuRoundedIcon></MenuRoundedIcon>
      </IconButton>
    </div>
  );
};

export default NavigationDrawer;
