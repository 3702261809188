import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import "../../Styles/style.css";

const MatchOverview = (props) => {
  return (
    <div className="container">
      <Paper
        data-testid="matchOverview"
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 500,
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item>{props.match.homeTeam.name}</Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {props.match.season.competition.name}:
                  {props.match.season.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {props.homeGoals} - {props.awayGoals}
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div">
                {props.match.awayTeam.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default MatchOverview;
