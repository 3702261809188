import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditCompetitionButton from "./EditCompetitionButton";
import DeleteCompetitionButton from "./DeleteCompetitionButton";

const CompetitionsTable = (props) => {
  const navigate = useNavigate();
  const handleRowClick = (row) => {
    navigate(`/competition/${row.id}`);
  };

  const rows = props.competitions.map(
    ({ id, name, description, url, user }) => ({
      id: id,
      name: name,
      description: description,
      url: url,
      owner: user.username,
    })
  );

  const competitionsWithPermission = props.competitionsWithPermission.map(
    (competition) => competition.id
  );

  return (
    <div>
      <TableContainer data-testid="competitionsTable" component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {competitionsWithPermission.length > 0 && <TableCell></TableCell>}
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Owner</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                data-testid={"competitionTableRow" + row.id}
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleRowClick(row)}
              >
                {competitionsWithPermission.includes(row.id) && (
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: 160 }}
                  >
                    <Box display="flex">
                      <EditCompetitionButton
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        competition={row}
                      />
                      <DeleteCompetitionButton
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                        competition={row}
                      />
                    </Box>
                  </TableCell>
                )}
                {!competitionsWithPermission.includes(row.id) &&
                  competitionsWithPermission.length > 0 && (
                    <TableCell></TableCell>
                  )}
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.url}</TableCell>
                <TableCell>{row.owner}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CompetitionsTable;
