import { gql } from "@apollo/client";

export const GET_PLAYER_BY_USER_ID = gql`
  query playerByUserId($id: Long) {
    playerByUserId(id: $id) {
      id
      name
      user {
        id
        username
      }
      team {
        id
        name
      }
    }
  }
`;

export const GET_PLAYERS_BY_TEAM_ID = gql`
  query playersByTeamId($id: Long) {
    playersByTeamId(id: $id) {
      id
      name
      user {
        id
        username
      }
      team {
        id
        name
      }
    }
  }
`;

export const GET_PLAYER = gql`
  query player($id: Long) {
    player(id: $id) {
      id
      name
      user {
        id
        username
      }
      team {
        id
        name
      }
    }
  }
`;

export const GET_CURRENT_PLAYER = gql`
  query GetCurrentPlayer {
    currentPlayer {
      id
      name
      user {
        id
        username
      }
      team {
        id
        name
      }
    }
  }
`;

export const DELETE_PLAYER = gql`
  mutation deletePlayer($id: Long!) {
    deletePlayer(id: $id) {
      id
      name
      user {
        id
        username
      }
      team {
        id
        name
      }
    }
  }
`;

export const UPDATE_PLAYER = gql`
  mutation updatePlayer($id: Long!, $name: String!) {
    updatePlayer(id: $id, name: $name) {
      id
      name
      user {
        id
        username
      }
      team {
        id
        name
      }
    }
  }
`;

export const REGISTER_PLAYER = gql`
  mutation registerPlayer {
    registerPlayer {
      id
      name
      user {
        id
        username
      }
      team {
        id
        name
      }
    }
  }
`;

export const GET_PLAYERS_BY_MATCH_ID = gql`
  query playersByMatchId($id: Long) {
    playersByMatchId(id: $id) {
      id
      name
      user {
        id
        username
      }
      team {
        id
        name
      }
    }
  }
`;

export const GET_PLAYERS = gql`
  query players {
    players {
      id
      name
      user {
        id
        username
      }
      team {
        id
        name
      }
    }
  }
`;
