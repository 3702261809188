import React, { useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import {
  GET_MATCH_BY_ID,
  MATCHES_WITH_PERMISSION,
} from "../../GraphQL/Matches.js";
import {
  GET_APPEARANCES_BY_MATCH_ID,
  APPEARANCES_WITH_PERMISSION,
} from "../../GraphQL/Appearances.js";
import { TEAMS_WITH_PERMISSION } from "../../GraphQL/Teams.js";
import { LoginContext } from "../../Components/Login/LoginContext";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import AppearanceTable from "../../Components/Appearance/AppearanceTable.js";
import NewAppearanceButton from "../../Components/Appearance/NewAppearanceButton.js";
import TeamButton from "../../Components/Team/TeamButton.js";
import SeasonButton from "../../Components/Season/SeasonButton.js";
import MatchOverview from "./MatchOverview.js";
import { GET_PLAYERS_BY_MATCH_ID } from "../../GraphQL/Players";
import "../../Styles/style.css";

const MatchPage = () => {
  const { matchId } = useParams();

  const match = useQuery(GET_MATCH_BY_ID, {
    variables: {
      id: matchId,
    },
  });

  const appearances = useQuery(GET_APPEARANCES_BY_MATCH_ID, {
    variables: {
      id: matchId,
    },
    pollInterval: 5000,
  });

  const possiblePlayers = useQuery(GET_PLAYERS_BY_MATCH_ID, {
    variables: {
      id: matchId,
    },
  });

  let matchesWithPermission = useQuery(MATCHES_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  const appearancesWithPermission = useQuery(APPEARANCES_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  let teamsWithPermission = useQuery(TEAMS_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  const [loggedIn] = useContext(LoginContext);

  if (
    match.loading ||
    appearances.loading ||
    possiblePlayers.loading ||
    matchesWithPermission.loading ||
    appearancesWithPermission.loading ||
    teamsWithPermission.loading
  )
    return <LoadingDialog />;

  const homePlayers = possiblePlayers.data.playersByMatchId.filter((player) => {
    return player.team.id === match.data.match.homeTeam.id;
  });

  const awayPlayers = possiblePlayers.data.playersByMatchId.filter((player) => {
    return player.team.id === match.data.match.awayTeam.id;
  });

  const homeAppearances = appearances.data.appearancesByMatchId.filter(
    (appearance) => {
      return appearance.team.id === match.data.match.homeTeam.id;
    }
  );

  const awayAppearances = appearances.data.appearancesByMatchId.filter(
    (appearance) => {
      return appearance.team.id === match.data.match.awayTeam.id;
    }
  );

  const homeGoals = homeAppearances.reduce((accumulator, appearance) => {
    return accumulator + appearance.goals;
  }, 0);

  const awayGoals = awayAppearances.reduce((accumulator, appearance) => {
    return accumulator + appearance.goals;
  }, 0);

  matchesWithPermission = matchesWithPermission.data.matchesWithPermission.map(
    (match) => match.id
  );

  teamsWithPermission = teamsWithPermission.data.teamsWithPermission.map(
    (team) => team.id
  );

  const canCreateAppearanceHome =
    loggedIn &&
    Array.isArray(teamsWithPermission) &&
    teamsWithPermission.includes(parseInt(match.data.match.homeTeam.id));

  const canCreateAppearanceAway =
    loggedIn &&
    Array.isArray(teamsWithPermission) &&
    teamsWithPermission.includes(parseInt(match.data.match.awayTeam.id));

  return (
    <div className="container">
      <h3>Match</h3>
      <MatchOverview
        match={match.data.match}
        homeGoals={homeGoals}
        awayGoals={awayGoals}
      ></MatchOverview>

      <TeamButton team={match.data.match.homeTeam}></TeamButton>
      <TeamButton team={match.data.match.awayTeam}></TeamButton>
      <SeasonButton season={match.data.match.season}></SeasonButton>

      <div className="row mt-5">
        <div className="col-6">
          <h3>{match.data.match.homeTeam.name} Appearances</h3>
          <AppearanceTable
            appearancesWithPermission={
              appearancesWithPermission.data.appearancesWithPermission
            }
            appearances={homeAppearances}
          ></AppearanceTable>
          {canCreateAppearanceHome && (
            <NewAppearanceButton
              matchId={matchId}
              players={homePlayers}
              teamId={match.data.match.homeTeam.id}
            ></NewAppearanceButton>
          )}
        </div>

        <div className="col-6">
          <h3>{match.data.match.awayTeam.name} Appearances</h3>
          <AppearanceTable
            appearancesWithPermission={
              appearancesWithPermission.data.appearancesWithPermission
            }
            appearances={awayAppearances}
          ></AppearanceTable>
          {canCreateAppearanceAway && (
            <NewAppearanceButton
              matchId={matchId}
              teamId={match.data.match.awayTeam.id}
              players={awayPlayers}
            ></NewAppearanceButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchPage;
