import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { UPDATE_APPEARANCE } from "../../GraphQL/Appearances";

const EditAppearanceDialog = (props) => {
  const [appearance, setAppearance] = useState("");

  useEffect(() => {
    setAppearance(props.appearance);
  }, [props]);

  const [updateAppearance, { loading, error }] = useMutation(
    UPDATE_APPEARANCE,
    {
      variables: {
        id: props.appearance.id,
        goals: appearance.goals,
        assists: appearance.assists,
        passes: appearance.passes,
        interceptions: appearance.interceptions,
        tackles: appearance.tackles,
        yellowCards: appearance.yellowCards,
        redCards: appearance.redCards,
      },
    }
  );

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateAppearance();
            props.setOpen(false);
          } else if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Appearance</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="player"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.player.name}
            disabled
          />
          <TextField
            data-testid="goals"
            margin="dense"
            id="goals"
            label="Goals"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.goals}
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, goals: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="assists"
            margin="dense"
            id="assists"
            label="Assists"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.assists}
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, assists: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="passes"
            margin="dense"
            id="passes"
            label="Passes"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.passes}
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, passes: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="interceptions"
            margin="dense"
            id="interceptions"
            label="Interceptions"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.interceptions}
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, interceptions: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="tackles"
            margin="dense"
            id="tackles"
            label="Tackles"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.tackles}
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, tackles: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="yellowCards"
            margin="dense"
            id="yellowCards"
            label="Yellow Cards"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.yellowCards}
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 2) value = 2;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, yellowCards: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 2 } }}
          />
          <TextField
            data-testid="redCards"
            margin="dense"
            id="redCards"
            label="Red Cards"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.redCards}
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 1) value = 1;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, redCards: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 1 } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveEditAppearanceDialogButton"
            onClick={() => {
              updateAppearance();
              props.setOpen(false);
            }}
          >
            Update
          </Button>
          <Button
            data-testid="closeEditAppearanceDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditAppearanceDialog;
