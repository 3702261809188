import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { REGISTER_PLAYER } from "../../GraphQL/Players";

const RegisterPlayerDialog = (props) => {
  const [registerPlayer, { loading, error }] = useMutation(REGISTER_PLAYER);

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            registerPlayer();
            props.setOpen(false);
          } else if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Register as Player</DialogTitle>
        <DialogContent>Player</DialogContent>
        <DialogActions>
          <Button
            data-testid="saveRegisterPlayerDialogButton"
            onClick={() => {
              registerPlayer();
              props.setOpen(false);
            }}
          >
            Register as Player
          </Button>
          <Button
            data-testid="closeRegisterPlayerDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RegisterPlayerDialog;
