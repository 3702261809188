import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { ADD_COMPETITION } from "../../GraphQL/Competitions.js";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";

const NewCompetitionDialog = (props) => {
  const [competition, setCompetition] = useState("");

  const [addCompetition, { loading, error }] = useMutation(ADD_COMPETITION, {
    variables: {
      name: competition.name,
      description: competition.description,
      url: competition.url,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            addCompetition();
            props.setOpen(false);
          } else if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Competition</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setCompetition({ ...competition, name: e.target.value });
            }}
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            data-testid="description"
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) =>
              setCompetition({ ...competition, description: e.target.value })
            }
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            data-testid="url"
            margin="dense"
            id="url"
            label="URL"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) =>
              setCompetition({ ...competition, url: e.target.value })
            }
            inputProps={{ maxLength: 20 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveNewCompetitionDialogButton"
            onClick={() => {
              addCompetition();
              props.setOpen(false);
            }}
          >
            Create
          </Button>
          <Button
            data-testid="closeNewCompetitionDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewCompetitionDialog;
