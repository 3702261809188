import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../Loading/LoadingDialog";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { ADD_TEAM_TO_SEASON } from "../../GraphQL/Season";
import TeamSelect from "./TeamSelect";

const AddTeamToSeasonDialog = (props) => {
  const [team, setTeam] = useState(null);

  const [addTeamToSeason, { loading, error }] = useMutation(
    ADD_TEAM_TO_SEASON,
    {
      variables: {
        seasonId: props.seasonId,
        teamId: team,
      },
    }
  );

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            addTeamToSeason();
            props.setOpen(false);
          } else if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Team</DialogTitle>
        <DialogContent>
          <TeamSelect
            seasonId={props.seasonId}
            team={team}
            setTeam={setTeam}
            teams={props.teams}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveAddTeamToSeasonDialogButton"
            onClick={() => {
              addTeamToSeason();
              props.setOpen(false);
            }}
          >
            Add
          </Button>
          <Button
            data-testid="closeAddTeamToSeasonDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddTeamToSeasonDialog;
