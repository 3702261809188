import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const ErrorDialog = (props) => {
  return (
    <div>
      <Dialog open={true}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent data-testid="errorDialog">
          Error...{props.message}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ErrorDialog;
