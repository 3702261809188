import React, { useState } from "react";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditSeasonDialog from "./EditSeasonDialog";

const EditSeasonButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="editSeasonButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditIcon />
      </Button>

      <EditSeasonDialog
        open={open}
        setOpen={setOpen}
        season={props.season}
      ></EditSeasonDialog>
    </div>
  );
};

export default EditSeasonButton;
