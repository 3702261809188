import { useState } from "react";
import { Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NewSeasonDialog from "./NewSeasonDialog";

const NewSeasonButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box display="flex">
        <Button
          data-testid="newSeasonButton"
          sx={{ marginLeft: 1 }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          Create Season
        </Button>
      </Box>

      <NewSeasonDialog
        competitionId={props.competitionId}
        open={open}
        setOpen={setOpen}
      ></NewSeasonDialog>
    </div>
  );
};

export default NewSeasonButton;
