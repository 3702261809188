import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import RegisterPlayerDialog from "./RegisterPlayerDialog";

const RegisterPlayerButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box>
        <Button
          data-testid="registerPlayerButton"
          sx={{ marginLeft: 1 }}
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          Register as Player
        </Button>
      </Box>

      <RegisterPlayerDialog
        open={open}
        setOpen={setOpen}
      ></RegisterPlayerDialog>
    </div>
  );
};

export default RegisterPlayerButton;
