import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      discriminator
      discordId
      username
      email
      icon
      originId
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: Long!
    $discordId: String!
    $discriminator: Long!
    $username: String!
    $email: String!
    $icon: String!
    $originId: String!
  ) {
    updateUser(
      id: $id
      discordId: $discordId
      discriminator: $discriminator
      username: $username
      email: $email
      icon: $icon
      originId: $originId
    ) {
      id
      discordId
      discriminator
      username
      email
      icon
      originId
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: Long!) {
    deleteUser(id: $id) {
      id
      discordId
      discriminator
      username
      email
      icon
      originId
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      discordId
      discriminator
      username
      email
      icon
      originId
      token
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: Long) {
    user(id: $id) {
      id
      discordId
      discriminator
      username
      email
      icon
      originId
    }
  }
`;
