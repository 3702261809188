import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTransferRequestDialog from "./DeleteTransferRequestDialog";

const DeleteTransferRequestButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="deleteTransferRequestButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DeleteIcon />
      </Button>

      <DeleteTransferRequestDialog
        open={open}
        setOpen={setOpen}
        transferRequest={props.transferRequest}
      ></DeleteTransferRequestDialog>
    </div>
  );
};

export default DeleteTransferRequestButton;
