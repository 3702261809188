import React, { useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_TEAM } from "../../GraphQL/Teams.js";
import { GET_SEASONS_CONTAINING_TEAM_ID } from "../../GraphQL/Season.js";
import { GET_PLAYERS_BY_TEAM_ID } from "../../GraphQL/Players.js";
import { LoginContext } from "../../Components/Login/LoginContext";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ManagerButton from "../../Components/Manager/ManagerButton.js";
import SeasonsTable from "../../Components/Season/SeasonsTable.js";
import PlayersTable from "../../Components/Player/PlayersTable.js";
import "../../Styles/style.css";

const TeamPage = (props) => {
  const { teamId } = useParams();

  const team = useQuery(GET_TEAM, {
    variables: {
      id: teamId,
    },
  });

  const seasons = useQuery(GET_SEASONS_CONTAINING_TEAM_ID, {
    variables: {
      id: teamId,
    },
    pollInterval: 5000,
  });

  const players = useQuery(GET_PLAYERS_BY_TEAM_ID, {
    variables: {
      id: teamId,
    },
    pollInterval: 5000,
  });

  const [loggedIn] = useContext(LoginContext);

  if (team.loading || seasons.loading || players.loading)
    return <LoadingDialog />;

  return (
    <div className="container">
      <h3>Team</h3>
      <ManagerButton managerId={team.data.team.manager.id}></ManagerButton>
      <h3>Seasons</h3>
      <SeasonsTable
        seasonsWithPermission={[]}
        seasons={seasons.data.seasonsContainingTeamId}
      ></SeasonsTable>
      <h3>Players</h3>
      <PlayersTable
        playersWithPermission={[]}
        players={players.data.playersByTeamId}
      ></PlayersTable>
    </div>
  );
};

export default TeamPage;
