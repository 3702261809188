import React, { useState } from "react";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditAppearanceDialog from "./EditAppearanceDialog";

const EditAppearanceButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="editAppearanceButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditIcon />
      </Button>

      <EditAppearanceDialog
        open={open}
        setOpen={setOpen}
        appearance={props.appearance}
      ></EditAppearanceDialog>
    </div>
  );
};

export default EditAppearanceButton;
