import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ManagerButton = (props) => {
  return (
    <div>
      <Button
        data-testid="managerButton"
        component={Link}
        to={"/manager/" + props.managerId}
        sx={{ marginLeft: 1 }}
        variant="contained"
      >
        Manager
      </Button>
    </div>
  );
};

export default ManagerButton;
