import { gql } from "@apollo/client";

export const GET_MANAGERS = gql`
  query GetManagers {
    managers {
      id
      user {
        id
        username
      }
    }
  }
`;

export const GET_CURRENT_MANAGER = gql`
  query GetCurrentManager {
    currentManager {
      id
      user {
        id
        username
      }
    }
  }
`;

export const GET_MANAGER = gql`
  query manager($id: Long) {
    manager(id: $id) {
      id
      user {
        id
        username
      }
    }
  }
`;

export const GET_MANAGER_BY_USER_ID = gql`
  query managerByUserId($userId: Long) {
    managerByUserId(userId: $userId) {
      id
      user {
        id
        username
      }
    }
  }
`;

export const REGISTER_MANAGER = gql`
  mutation registerManager {
    registerManager {
      id
      user {
        id
        username
      }
    }
  }
`;
