import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditSeasonButton from "./EditSeasonButton";
import DeleteSeasonButton from "./DeleteSeasonButton";

const SeasonsTable = (props) => {
  const navigate = useNavigate();
  const handleRowClick = (row) => {
    navigate(`/season/${row.id}`);
  };

  const rows = props.seasons.map(
    ({ id, name, startDate, endDate, competition }) => ({
      id: id,
      name: name,
      startDate: startDate,
      endDate: endDate,
      competition: competition.name,
    })
  );

  const seasonsWithPermission = props.seasonsWithPermission.map(
    (season) => season.id
  );

  return (
    <div>
      <TableContainer data-testid="seasonsTable" component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {seasonsWithPermission.length > 0 && <TableCell></TableCell>}
              <TableCell>Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Competition</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                data-testid={"seasonTableRow" + row.id}
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleRowClick(row)}
              >
                {seasonsWithPermission.includes(row.id) && (
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: 160 }}
                  >
                    <Box display="flex">
                      <EditSeasonButton
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        season={row}
                      />
                      <DeleteSeasonButton
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                        season={row}
                      />
                    </Box>
                  </TableCell>
                )}
                {!seasonsWithPermission.includes(row.id) &&
                  seasonsWithPermission.length > 0 && <TableCell></TableCell>}
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.startDate}</TableCell>
                <TableCell>{row.endDate}</TableCell>
                <TableCell>{row.competition}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SeasonsTable;
