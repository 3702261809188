import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { DELETE_TRANSFER_REQUEST } from "../../GraphQL/TransferRequests";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";

const DeleteTransferRequestDialog = (props) => {
  const [transferRequest, setTransferRequest] = useState("");

  useEffect(() => {
    setTransferRequest(props.transferRequest);
  }, [props]);

  const [deleteTransferRequest, { loading, error }] = useMutation(
    DELETE_TRANSFER_REQUEST,
    {
      variables: {
        id: props.transferRequest.id,
      },
    }
  );

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            props.setOpen(false);
          }
        }}
      >
        <DialogTitle>Transfer Request</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="playerName"
            autoFocus
            margin="dense"
            id="playerName"
            label="Player Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.transferRequest.player.name}
            disabled
          />
          <TextField
            data-testid="teamName"
            margin="dense"
            id="teamName"
            label="Team Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.transferRequest.team.name}
            disabled
          />
          <FormControlLabel
            label="Player Accepted"
            control={
              <Checkbox
                data-testid="playerAccepted"
                margin="dense"
                id="playerAccepted"
                label="Player Accepted"
                variant="standard"
                defaultChecked={props.transferRequest.playerAccepted}
                disabled
              />
            }
          />
          <FormControlLabel
            label="Team Accepted"
            control={
              <Checkbox
                data-testid="teamAccepted"
                margin="dense"
                id="teamAccepted"
                label="Team Accepted"
                variant="standard"
                defaultChecked={props.transferRequest.teamAccepted}
                disabled
              />
            }
          />
          <TextField
            data-testid="requestDate"
            margin="dense"
            id="requestDate"
            label="Request Date"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.transferRequest.requestDate}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="deleteDeleteTransferRequestDialogButton"
            onClick={() => {
              deleteTransferRequest();
              props.setOpen(false);
            }}
          >
            Delete
          </Button>
          <Button
            data-testid="closeDeleteTransferRequestDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteTransferRequestDialog;
